import { useParams } from 'react-router-dom'
import dataGruaPlumas from "../JsonFiles/dataGruaPlumas.json";
import dataGruaPluma from "../JsonFiles/DetallesProductos/GruaPluma/dataGruaPluma.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'

function GruaPluma(){
    const {gruapluma} = useParams()
    return (<>
    <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    {dataTitle(dataGruaPlumas, gruapluma, "Grua Pluma", "grua-pluma")}
                    {gruapluma ? dataDetallado(dataGruaPluma, gruapluma): dataProductos(dataGruaPlumas)}   
                </div>
            </div>
        </div>
    </div>
           </>
    );
}
export default GruaPluma;