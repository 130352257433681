import React, {useState} from 'react'
import '../../Assets/Stylesheets/bootstrap-5.0.2/css/bootstrap.min.css'
import emailjs from 'emailjs-com'
import {ConfigEmail} from '../Configuracion/ConfigEmail'

const Contactanos = () => {

    /* const EnviarEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_prw14k7', 'template_g5a7jet', e.target, 'mG46WKk-TiZMjNs93').then(
            res => {
                alert("Enviado Satifactoriamente")
                console.log(res)
            }
        )

    }

    return (<> 
    <div className="card mt-2">
            <div className="card-body">
                <p className="p-center">Nuestra empresa posee dos correos:&nbsp;&nbsp;<b>transelevavzla@gmail.com</b> &amp; <b>transeleva@gmail.com</b></p>
                
                <p className="p-center">Si deseas comunicarte con nosotros este es nuestro medio de contacto</p>
                
                <p className="p-center"><b>0412 - 844.88.63</b> /&nbsp;<b>0412- 679.16.25</b> /&nbsp;<b>0412 - 779.29.99</b></p>

                    <form onSubmit={EnviarEmail} className="form-inline">
                        <div className="row justify-content-center">
                            <div className="col-sm-4">
                                <label className="col-form-label">Nombre y Apellido: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="name_ape" id="name_ape" placeholder="Ingrese su Nombre y Apellido" required=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-sm-4">
                                <label className="col-form-label">Empresa: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="empresa" id="empresa" placeholder="Ingrese el Nombre de la Empresa" required=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-sm-4">
                                <label className="col-form-label">RIF: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="rif" id="rif" placeholder="Ingrese el RIF de su Empresa" required=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-sm-4">
                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Enviar"/>
                            </div>
                            </div>
                            
                        </div>
                        
                    </form>
            </div>
        </div>
    </>)
} 
export default Contactanos; */

const frmContact = { name_ape:``, empresa:``, rif:``, correo_e:``, telefono:``, asunto:``, mensaje:`` };
    const [contact,setContact] = useState(frmContact);
    const [showMessage, setShowMessage] = useState(false);

	const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value});
   };
   const EnviarEmail = e =>{
	    e.preventDefault();
		emailjs.send('service_prw14k7', 'template_g5a7jet', contact, 'mG46WKk-TiZMjNs93')
        
		.then((response) => {
				   console.log("SUCCESS!", response.status, response.text);
				   setContact(frmContact);
				   //setShowMessage(true);
                   alert("Enviado Satifactoriamente")
                   e.target.reset()
		}, (err) => {
				   console.log("FAILED...", err);
		});
   }
    
  

    return (<> 
    <div className="card mt-2">
            <div className="card-body">
                <p className="p-center">Nuestra empresa posee dos correos:&nbsp;&nbsp;<b>transelevavzla@gmail.com</b> &amp; <b>transeleva@gmail.com</b></p>
                
                <p className="p-center">Si deseas comunicarte con nosotros este es nuestro medio de contacto</p>
                
                <p className="p-center"><b>0412 - 844.88.63</b> /&nbsp;<b>0412- 679.16.25</b> /&nbsp;<b>0412 - 779.29.99</b></p>

                { showMessage ? <div className="alert alert-success alert-dismissible fade show" role="alert"><center>Enviado <strong>Exitosamente</strong></center>
				<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div> : ``}

                <form onSubmit={EnviarEmail}>
				<div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Nombre y Apellido: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="name_ape"  value={contact.emailTitle} onChange={handleChange} id="name_ape" placeholder="Ingrese su Nombre y Apellido" required=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Empresa: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="empresa" value={contact.emailTitle} onChange= {handleChange} id="empresa" placeholder="Ingrese el Nombre de la Empresa" required=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">RIF: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="rif" id="rif"  value={contact.emailTitle} onChange={handleChange} placeholder="Ingrese el RIF de su Empresa" required=""/>
                                </div>
                            </div>
                        </div>

						<div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Correo Electronico: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="email" name="correo_e"  value={contact.emailTitle} onChange={handleChange} id="correo_e" placeholder="Ingrese su Correo Electronico" required=""/>
                                </div>
                            </div>
                        </div>

						<div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Telefono: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="telefono"  value={contact.emailTitle} onChange={handleChange} id="telefono" placeholder="Ingrese su Telefono" required=""/>
                                </div>
                            </div>
                        </div>

						<div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Asunto: *</label>
                                <div className="form-group">
                                <input className=" form-control" type="text" name="asunto" value={contact.emailTitle} onChange= {handleChange} id="asunto" placeholder="Ingrese el Asunto" required=""/>
                                </div>
                            </div>
                        </div>

						<div className="row justify-content-center">
                            <div className="col-lg-4">
                                <label className="col-form-label">Mensaje: *</label>
                                <div className="form-group">
                                <textarea className=" form-control" type="text" name="mensaje"  value={contact.emailTitle} onChange={handleChange} id="mensaje" placeholder="Ingrese el Mensaje"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-lg-4">
                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Enviar"/>
                            </div>
                            </div>
                            
                        </div>
		</form>
            </div>
        </div>
    </>)
} 
export default Contactanos;