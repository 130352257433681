import { useParams } from 'react-router-dom'
import dataBolaTransferencias from "../JsonFiles/dataBolaTransferencias.json";
import dataBolaTransferencia from "../JsonFiles/DetallesProductos/BolaTransferencia/dataBolaTransferencia.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'

function BolaTransferencia(){
    const {bolatransferencia} = useParams()
    return (<>
    <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    {dataTitle(dataBolaTransferencias, bolatransferencia, "Bola de Transferencia", "bola-de-transferencia")}
                    {bolatransferencia ? dataDetallado(dataBolaTransferencia, bolatransferencia): dataProductos(dataBolaTransferencias)}   
                </div>
            </div>
        </div>
    </div>
        </>
    );
}
export default BolaTransferencia;