import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

/* import { IconContext } from "react-icons"; */

{/* <IconContext.Provider value={{ color: "blue", className: "global-class-name" }}>
  <div>
    <FaFolder />
  </div>
</IconContext.Provider>;
Key	Default	Notes
color	undefined (inherit)	
size	1em	
className	undefined	
style	undefined	Can overwrite size and color
attr	undefined	Overwritten by other attributes
title	undefined	Icon description for accessibility */}

const Header = () => {
    return (
        <header className="Header">
          <span className="Span-Header"> <FaPhoneAlt /> +58 412 844.88.63 / 679.16.25</span>
          <span className="Span-Header"> <FaEnvelope /> transelevavzla@gmail.com</span>
        </header>
    );
};

export default Header;