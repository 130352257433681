import { Link, useParams } from 'react-router-dom'
import DataTranspaleta from '../../../JsonFiles/DetallesProductos/Transpaleta/dataTranspaleta.json'

let rutaRegresar = "transpaletas";

const GettingTitle = (modeloManufactura) => {
    let midata = DataTranspaleta.find((elemento)=>elemento.Modelo ===modeloManufactura)
    return(
        <header className="entry-header">
            <h3>{midata.Title}</h3>
        </header>
        )
}

const DataFiltrado = (modeloManufactura) => {
    const dataBody = DataTranspaleta.filter((elemento)=>elemento.Modelo ===modeloManufactura)
    return(
        <div className="row">
                <div className="col-lg-4 img-product-detail-content">
                {<center><img className="img-product-detail" key={dataBody.No} src={(require(`../../../../Assets/Img/${dataBody[0].FolderImagen1}/${dataBody[0].FolderImagen2}/${dataBody[0].Imagen}`).default)} alt={dataBody[0].alt}/></center>}
                    <figcaption>
                        <strong><center>{dataBody[0].Descripcion}</center></strong>
                    </figcaption>
                </div>

                <div className="col-lg-8 col-sm-12">
                    <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered">                        
                        <thead>
                            <tr className="colorCabecera">
                            {dataBody[0] && dataBody[0].Cabecera.map((mihead)=>
                                <th>{mihead.nombre}</th>
                            )}
                            </tr>
                        </thead>

                        <tbody> 
                                                   
                        {dataBody[0] && dataBody[0].Data.map((midata)=>
                            <tr key={midata.id}>
                                <td>{midata.descripcion}</td>
                                <td>{midata.valor1}</td> 
                                {midata.valor2===undefined ? "":<td>{midata.valor2}</td>}  
                                {midata.valor3===undefined ? "":<td>{midata.valor3}</td>}  
                            </tr>
                        )
                        }
                        </tbody>
                    </table>        
                    </div>
                
                </div>
             </div>
    )
}

function  Transpaletas (){
    const {modeloTranspaleta} = useParams() 
    return (<>


<div className="container main-page mt-2">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-0">
                            <div className="col-12">
                                {GettingTitle(modeloTranspaleta)}
                                {modeloTranspaleta && <Link to={`/${rutaRegresar}`} className="enlaceRegresar">Regresar</Link>}
                            </div>                            
                        </div>
                {DataFiltrado(modeloTranspaleta)}    
            </div> 
            </div>
            </div>
            </div>
        </>
    );
}
export default Transpaletas;