import React from 'react';
import { Link } from 'react-router-dom'

const dataProductos = (MyArray) => {
    return(
        <div className="row">
            {MyArray.map( item => 
                <div className={`${item.Col} col-xs-12 col-sm-12 col-md-12 col-12`}>
                    <div className="card card-unique">
                        <div className="card-body">
                        <center>
                            <Link key={item.No} to={`/${item.Name}/${item.Modelo}`}>
                                <img key={item.No} className="img-products" src={(require(`../../Assets/Img/${item.FolderImages}/${item.ruta}`).default)} width={item.Width} height={item.Height} />
                            </Link>
                        </center>
                            <div className="mt-1 btn-detail-before btn-details">
                                <Link key={item.No} to={`/${item.Name}/${item.Modelo}`} className="btn btn-secondary text-white">Detalles</Link>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>
    )
}

export default dataProductos;