import React from 'react';
import { Link } from 'react-router-dom'

const dataTitle = (Myarray, EnlaceParams, Title, rutaRegresar) => {
    let midata = Myarray.find((elemento)=>elemento.Modelo ===EnlaceParams)
    return(<>
        <div className="card-body pb-0">    
                <div className="col-12">
                    {EnlaceParams ? midata && <header className="entry-header"><h3>{midata.Title}</h3></header>: <h3>{Title} </h3>}    
                    {EnlaceParams && midata && <Link to={`/${midata.Name}`} className="enlaceRegresar">Regresar</Link>}
                </div> 
        </div>
        </>
    )
}

export default dataTitle;