import { useParams } from 'react-router-dom'
import dataMesatrabajos from "../JsonFiles/dataMesatrabajos.json";
import dataMesaTrabajo from "../JsonFiles/DetallesProductos/MesaTrabajo/dataMesaTrabajo.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'

function MesaTrabajo(){
    const {mesa} = useParams()
    return (<>
    <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    {dataTitle(dataMesatrabajos, mesa, "Mesa de Trabajo", "mesa-de-trabajo")}
                    {mesa ? dataDetallado(dataMesaTrabajo, mesa): dataProductos(dataMesatrabajos)}   
                </div>
            </div>
        </div>
    </div>
        </>
    );
}
export default MesaTrabajo;