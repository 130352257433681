/* import '../../Assets/Stylesheets/bootstrap-5.0.2/css/bootstrap.min.css' */
import React, { useState} from 'react'

function Nosotros () {
    const [StateBtn, setStateBtn] = useState(0)

    const Encender = () => {
        /* setStateBtn(!StateBtn) */
        setStateBtn(prevValue=>!prevValue)

    }
    return (<>
    <div className="container main-page mt-2">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                    <div class="card-body">
            <h4><b>Misi&oacute;n</b></h4>
                <br></br>
                <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;Nuestra Misión es el de dar un servicio de calidad y segura acorde con  a la necesidad  independientemente a sus requerimientos, como asistencia técnica a sus requisitos para  fabricación de Equipos para el manejo de Materiales para el ramo de la producción, el almacenaje y distribución, sea paletizado o en tambores, con el fin de mejorar los costos y normas de seguridad en su desarrollo,  etc…. 

                    Nos encontramos Comprometidos con la satisfacción de las necesidades de nuestros clientes, a través de la innovación de equipos y servicios a la medida de sus requerimientos.</p>

                    <h4><b>Visión</b></h4>
                    <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;Nuestra visión de lo que requiere la comunidad industrial de un servicio  en función a la necesidad de los diversos procesos de la producción; ofrece asistencia técnica en desarrollo de su proyecto, fabricación, mantenimiento y mercadeo de equipos manuales para el acarreo y manejo de materiales, como: Transpaletas en diferentes categorías de capacidad, tamaño y para pasillos angostos, Apiladores hidráulicos &amp; Semi-eléctricos manuales, Elevador-Volteador manual porta tambores, Mesa de Trabajo, Elevador base angular para el paletizado, Elevador de eslinga para el paletizado y pesaje sobre báscula manual de tambores de metal y de plásticos,  Escalera hidráulica telescópica manual para el mantenimiento de alumbrado tuberías aéreas, Pluma ó Grúa para talleres mecánicos.</p>
            </div>
                    </div>
                </div>
            </div>
    </div>

    {/* <div className="row">
    <p>Mi car is {StateBtn ? "On" : "Off"}</p>
        <button className="btn btn-success" onClick={Encender}>Encender / Apagar</button>
    </div> */}
    </>)
} 
export default Nosotros;