import React, { Component } from 'react';
import '../../Assets/Stylesheets/Navegacion.css'
import '../../Assets/Stylesheets/Modules.css'

import Img1 from '../../Assets/Img/carousel/apilador.png'
import Img2 from '../../Assets/Img/carousel/carrito_elevador.png'
import Img3 from '../../Assets/Img/carousel/edit_bola.png'
import Img4 from '../../Assets/Img/carousel/escalera_telescopica.png'
import Img5 from '../../Assets/Img/carousel/grua_pluma_5.png'
import Img6 from '../../Assets/Img/carousel/portaplasticos.png'
import Img7 from '../../Assets/Img/carousel/portatambor.png'
import Img8 from '../../Assets/Img/carousel/portatambor_verde2.png'
import Img9 from '../../Assets/Img/carousel/portatamboreslinga.png'
import Img10 from '../../Assets/Img/carousel/transpaleta_naranja_3000kg.jpg'


class Carousel extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {   
        var myCarousel = document.querySelector('#carouselExampleFade')
        var carousel = new Carousel(myCarousel, {
        interval: 2000,
        wrap: false
        });
    }

    /* data-bs-interval="10" */

    render() {
        return (
            <>
        <div id="carouselExampleFade" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Img1} className="d-block w-100" alt="whatever"/>
                </div>
                <div className="carousel-item">
                    <img src={Img2} className="d-block w-100" alt="whatever"/>
                </div>
                <div className="carousel-item" >
                    <img src={Img3} className="d-block w-100" alt="whatever"/>
                </div>
                <div className="carousel-item" >
                    <img src={Img4} className="d-block w-100" alt="whatever"/>
                </div>
            </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
        </div>
    </>
        );
    }
}

Carousel.propTypes = {

};

export default Carousel;