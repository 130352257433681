import { Link } from 'react-router-dom'
import dataTranspaletas from "../JsonFiles/dataTranspaletas.json";
let Title = "Transpaletas";
function Transpaletas(){
    return (<>
        <div className="container main-page mt-2">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-0">
                            <div className="col-12">
                                <h3>{Title}</h3>
                            </div>                            
                        </div>
                   
                        <div className="row">
                            {dataTranspaletas.map( transpaleta => 
                                <div className={`${transpaleta.Col} col-xs-12 col-sm-12 col-md-12 col-12`}>
                                    <div className="card card-unique">
                                        <div className="card-body card-body-all">
                                            <center><Link key={transpaleta.No} to={`/transpaleta/${transpaleta.Modelo}`}><img key={transpaleta.No} className="img-products" src={(require(`../../Assets/Img/transpaletas/${transpaleta.ruta}`).default)} /></Link></center>
                                            <div className="mt-1 btn-detail-before btn-details">
                                                <Link key={transpaleta.No} to={`/transpaleta/${transpaleta.Modelo}`} className="btn btn-secondary text-white">Detalles</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                    </div> 
                </div>
            </div>
        </div>       
    </>
    );
}
export default Transpaletas;