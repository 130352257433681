import {Routes, Route} from 'react-router-dom'
import Inicio from "./Components/Modules/Inicio";
import Nosotros from "./Components/Modules/Nosotros";
import Navegacion from "./Components/Navegacion/Navegacion";
import Header from "./Components/Modules/Header";
import Contactanos from "./Components/Modules/Contactanos";
import NotFound from "./Components/Modules/NotFound";
import Transpaletas from "./Components/Modules/Transpaletas";
import Transpaleta from "./Components/Modules/DetallesProductos/Transpaleta/Transpaleta";
import ManufacturaPropias from "./Components/Modules/ManufacturaPropias";
import Apiladores from "./Components/Modules/Apiladores"
import PortaTambores from "./Components/Modules/PortaTambores"
import MesaTrabajo from "./Components/Modules/MesaTrabajo"
import GruaPluma from "./Components/Modules/GruaPluma"
import BolaTransferencia from "./Components/Modules/BolaTransferencia"
import Footer from "./Components/Modules/Footer";

const App = () => {
    return (
        <div className="Container">

            <Header/>
            <Navegacion/>             
                <Routes>                   
                    <Route path="/" element={<Inicio/>}>Inicioaaq</Route>
                    <Route path="/acerca-de" element={<Nosotros/>}>Acerca de </Route>                
                    <Route path="/contactanos" element={<Contactanos/>}>Contactanos </Route>
                    <Route path="/transpaletas" element={<Transpaletas/>}>Transpaletas </Route>
                    <Route path="/transpaleta/:modeloTranspaleta" element={<Transpaleta/>}>Transpaletas </Route>                
                    <Route path="/manufactura-propia" element={<ManufacturaPropias/>}>Manufactura Propias</Route>
                    <Route path="/manufactura-propia/:modeloManufactura" element={<ManufacturaPropias/>}>Manufactura Propias</Route>
                    <Route path="/apiladores" element={<Apiladores/>}></Route>
                    <Route path="/apiladores/:apilador" element={<Apiladores/>}></Route>
                    <Route path="/porta-tambores" element={<PortaTambores/>}></Route>
                    <Route path="/porta-tambores/:portatambor" element={<PortaTambores/>}></Route>
                    <Route path="/mesa-de-trabajo" element={<MesaTrabajo/>}>Mesa de Trabajo</Route>
                    <Route path="/mesa-de-trabajo/:mesa" element={<MesaTrabajo/>}>Mesa de Trabajo</Route>
                    <Route path="/grua-pluma" element={<GruaPluma/>}></Route>
                    <Route path="/grua-pluma/:gruapluma" element={<GruaPluma/>}></Route>
                    <Route path="/bola-de-transferencia" element={<BolaTransferencia/>}></Route>
                    <Route path="/bola-de-transferencia/:bolatransferencia" element={<BolaTransferencia/>}></Route>
                    <Route path="*" element={<NotFound/>}></Route>                
                </Routes>
            <Footer/>        
        </div>
    );
};

export default App;