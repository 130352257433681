import { useParams } from 'react-router-dom'
import dataApiladores from "../JsonFiles/dataApiladores.json";
import dataApilador from "../JsonFiles/DetallesProductos/Apilador/dataApilador.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'


function Apiladores(){
    const {apilador} = useParams()
    return (<>
    <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    {dataTitle(dataApiladores, apilador, "Apiladores", "apiladores")}
                    {apilador ? dataDetallado(dataApilador, apilador): dataProductos(dataApiladores)}   
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default Apiladores;