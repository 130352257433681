const NotFound = () => {
    return (
        <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="card-text">
                            <h2 className="text-center">img Lo sentimos, No fue encontrada</h2>    
                        </div>
                        
                    </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;