import '../../Assets/Stylesheets/Navegacion.css'
import LogoCompany from '../../Assets/Img/logo_transeleva.png'
import { NavLink as NavLinkReactRouter, Routes  } from 'react-router-dom'

const MyNavlink = ({to, children, ...props}) => {
    return(
        <NavLinkReactRouter {...props} to={to} classNameName={({isActive}) => {
            return isActive ? 'is-active' :'undefined'}}>
            {children}
        </NavLinkReactRouter> 
    )
}

function Navegacion (){
    return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
        <MyNavlink to="/" class="navbar-brand" >
            <img src={LogoCompany} alt="Transeleva" title="Transeleva" width="350" height="100" className="d-inline-block align-text-top img-company"/>
        </MyNavlink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <MyNavlink to="/" className="nav-link" aria-current="page">Inicio</MyNavlink>
                </li>
                <li className="nav-item">
                <MyNavlink to="/acerca-de" className="nav-link" >Acerca de</MyNavlink>
                </li>
                <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Productos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><MyNavlink to="/transpaletas" className="dropdown-item" >Transpaletas</MyNavlink></li>
                    <li><MyNavlink to="/apiladores" className="dropdown-item" >Apiladores</MyNavlink></li>
                    <li><MyNavlink to="/porta-tambores" className="dropdown-item" >Porta Tambores</MyNavlink></li>
                    <li><MyNavlink to="/mesa-de-trabajo" className="dropdown-item" >Mesa de Trabajo</MyNavlink></li>
                    <li><MyNavlink to="/grua-pluma" className="dropdown-item" >Grua Pluma</MyNavlink></li>
                    <li><MyNavlink to="/bola-de-transferencia" className="dropdown-item" >Bola de Transferencia</MyNavlink></li>
                    <li><MyNavlink to="/manufactura-propia" className="dropdown-item" >Manufactura Propia</MyNavlink></li>
                </ul>
                </li>
                <li className="nav-item">
                <MyNavlink to="/contactanos" className="nav-link" >Contactanos</MyNavlink>
                </li>
            </ul>
            </div>
        </div>
    </nav>
    )
}
export default Navegacion;