import { useParams } from 'react-router-dom'
import dataPortaTambores from "../JsonFiles/dataPortaTambores.json";
import dataPortaTambor from "../JsonFiles/DetallesProductos/PortaTambor/dataPortaTambor.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'

function PortaTambores(){
    const {portatambor} = useParams()
    return (<>
    <div className="container main-page mt-2">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    {dataTitle(dataPortaTambores, portatambor, "Porta Tambores", "porta-tambores")}
                    {portatambor ? dataDetallado(dataPortaTambor, portatambor): dataProductos(dataPortaTambores)}   
                </div>
            </div>
        </div>
    </div>
    </>)
}

export default PortaTambores;