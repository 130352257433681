import { Link, useParams } from 'react-router-dom'
import dataManufacturaPropias from "../JsonFiles/dataManufacturaPropias.json";
import dataManufacturaPropia from "../JsonFiles/DetallesProductos/ManufacturaPropia/dataManufacturaPropia.json";
import dataProductos from '../MiniComponentes/dataProductos'
import dataTitle from '../MiniComponentes/dataTitle'
import dataDetallado from '../MiniComponentes/dataDetallado'

function ManufacturaPropias(){
    const {modeloManufactura} = useParams()
    return (<>
    <div className="container main-page mt-2">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {dataTitle(dataManufacturaPropias, modeloManufactura, "Manufactura Propia", "manufactura-propia")}
                        {modeloManufactura ? dataDetallado(dataManufacturaPropia, modeloManufactura): dataProductos(dataManufacturaPropias)}   
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default ManufacturaPropias;