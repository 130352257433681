import React from 'react';
import NotFound from '../Modules/NotFound'

const dataDetallado = (MyArray, enlaceSecundario) => {
    const dataBody = MyArray.filter((elemento)=>elemento.Modelo === enlaceSecundario)
    return(
        dataBody[0]===undefined ? <NotFound/> : 
     <>   <div className="row">
        <div className={`col-lg-4 ${dataBody[0].ClassNameContent}`}>
        {<center><img key={dataBody[0].No} className={dataBody[0].ClassNameImg} src={(require(`../../Assets/Img/${dataBody[0].FolderImagen1}/${dataBody[0].FolderImagen2}/${dataBody[0].Imagen}`).default)} /></center>}
            <figcaption>
                <strong><center>{dataBody[0].Descripcion}</center></strong>
            </figcaption>
        </div>

        <div className="col-lg-8">
        <div className="table-responsive">
        <table className="table table-hover table-striped table-bordered">
                <thead className="colorCabecera">
                    <tr>
                    {dataBody[0]===undefined ? "" : dataBody[0].Cabecera.map((mihead)=>
                    <th>{mihead.nombre}</th>
                    )}
                    </tr>
                </thead>
                <tbody>                            
                {dataBody[0]===undefined ? "" : dataBody[0].Data.map((midata)=>
                    <tr key={midata.id}>
                        <td>{midata.descripcion}</td> 
                        <td>{midata.valor1}</td>                         
                        {midata.valor2 && <td>{midata.valor2}</td> }
                        {midata.valor3 && <td>{midata.valor3}</td> }
                    </tr>
                )
                }
                </tbody>
            </table>    
        </div>    
        
        </div>
        
     </div>

     {/* <div className="row">
            <div className="col-12 bg-danger">
            <figcaption>
                <strong><center>{dataBody[0].Descripcion}</center></strong>
            </figcaption>        
            </div>
        
        </div> */}
     </>
    )
}

export default dataDetallado;